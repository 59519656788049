import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { popupType } from 'src/app/shared/enums/popupEnums';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { PopupModal } from '../popups/popup.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';
import { Subscription } from 'rxjs';
import { EmitButtonData, FooterData } from 'src/app/shared/interface/custom-footer';
import { TravelDataStorageService } from 'src/app/modules/health/products/travel/travel.service';
import { TravelDataStorage, TravelPremiumDetails } from 'src/app/modules/health/products/travel/travel.modal';
import { HealthDataStorage, PremiumDetailsData } from 'src/app/modules/health/health.modal';
import { RenewalDataEmitService } from 'src/app/shared/services/renewal-emit-service';
import { ConvertSavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { RenewalDataStorageService } from 'src/app/shared/components/sidemenu/renewals/renewal.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ProductCode } from 'src/app/shared/enums/healthEnums';

@Component({
  selector: 'app-custom-footer',
  templateUrl: './custom-footer.component.html',
  styleUrls: ['./custom-footer.component.scss']
})
export class CustomFooterComponent implements OnChanges {
  showPaymentButtons: boolean = false
  screenSizeLessThan: boolean = false;
  windowResized: boolean = false;
  showPremiumInfo: boolean = false;
  paymentButtonData: FooterData[] = [];
  subscription: Subscription[] = [];
  comboSIValue: string;

  productType: string = '';

  @Input() normalButton: boolean;
  @Input() renewButton: boolean = false;
  @Input() isKYCDoneSuccessfully: boolean;
  @Input() renewalSummaryButtons: boolean;
  @Input() buttonData: Array<FooterData>;
  @Output() buttonClicked = new EventEmitter<EmitButtonData>();

  healthFormData: HealthDataStorage;
  travelFormData: TravelDataStorage;
  premiumDetailsData: PremiumDetailsData;
  travelPremiumDetailsData: TravelPremiumDetails;
  showRenewalFooter: boolean = false;
  showRenewalProposalButton: boolean = false;
  footerData: any;
  footerTotalPremium: any;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  zerotatFooter: boolean = false;
  renewalDataStorage: any;
  channelData: ChannelData;
  isOrionJourney: string;

  constructor(private footerService: CustomFooterService,
    public utilityService: UtilityService,
    private healthDataStorageService: HealthDataStorageService,
    private travelDataStorageService: TravelDataStorageService,
    private storageService: StorageService,
    private windowResizeService: WindowResizeService,
    private popupService: PopupService,
    private renewal_emit_servive: RenewalDataEmitService,
    private renewalStorageService: RenewalDataStorageService) { }

  ngOnInit() {
    this.productType = this.storageService.getAESDecryptedData(userEnums.ProductType)?this.storageService.getAESDecryptedData(userEnums.ProductType):'Health';
    this.isOrionJourney = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney);
    // For hiding and showing premium
    this.subscription.push(this.footerService.showPremium$.subscribe(data => {
      this.showPremiumInfo = data;
      this.renewalDataStorage = this.renewalStorageService.getRenewalDataStorage();
      if (this.productType?.toUpperCase() == 'TRAVEL') {
        this.constructTravelPremiumData();
      } else if (this.productType?.toUpperCase() == 'HEALTH') {
        this.constructPremiumData();
      }
      this.checkScreenWidth();
    }));


    this.subscription.push(this.footerService.showRenewalFooterData$.subscribe(data => {
      if (!this.utilityService.isEmptyOrNull(data) && data) {
        this.footerData = data;
        // Calculate optionalCovers and BasicPremium (or TotalPremium)
        const optionalCovers = this.footerData?.OptionalCovers ? this.footerData.OptionalCovers : 0;
        const totalPremium = this.footerData?.TotalPremium ? this.footerData.TotalPremium : 0;

        // Calculate the total premium
        const finalTotalPremium = (optionalCovers + totalPremium);

        // Assign the calculated total premium to the TotalPremium property
        this.footerTotalPremium = finalTotalPremium;

        this.showRenewalFooter = true;
      }
    }))


    // For updating premium details
    // Premium details will only be updated when premium is already set
    this.subscription.push(this.footerService.premiumInfoUpdated$.subscribe(premiumInfo => {
      if (premiumInfo.value == 'summary') {
        if (premiumInfo.update) {
          if (this.productType?.toUpperCase() == 'TRAVEL') {
            this.travelFormData.premiumFormData.totalPremium = premiumInfo.data;
            this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
            this.premiumDetailsData.totalPremium = this.travelFormData.premiumFormData.totalPremium;
          } else {
            this.healthFormData.premiumFormData.totalPremium = premiumInfo.data;
            this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
            this.premiumDetailsData.totalPremium = this.healthFormData.premiumFormData.totalPremium;
          }
        }
      }
    }));

    this.zerotatFooter = this.renewalDataStorage.ProductType === 23 || this.renewalDataStorage.ProductType === 38;
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  //   // Handle changes to input properties here
  //   if (changes['renewalSummaryButtons']) {
  //     const newValue = changes['renewalSummaryButtons'].currentValue;
  //     if (newValue) {
  //       this.paymentButtonData.forEach(data => {
  //         data.hide = false;
  //       })

  //       if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG == true || this.channelData.BFLAG == "true")) {
  //         this.paymentButtonData = this.paymentButtonData.filter(data => (data.label != 'Pay Later' && data.label != 'Pay Now'))
  //         this.buttonData = this.buttonData.filter(data => (data.label != 'Pay Later' && data.label != 'Pay Now'))
  //       }
  //     }
  //   }
  // }

  ngOnChanges(changes: SimpleChanges): void {
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);

    if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG == true || this.channelData.BFLAG == "true")) {
      this.paymentButtonData = this.paymentButtonData.filter(data => (data.label != 'Pay Later' && data.label != 'Payment Link'))
      this.buttonData = this.buttonData.filter(data => (data.label != 'Pay Later' && data.label != 'Payment Link'))
    } else {
      if (!this.normalButton) {
        this.showPaymentButtons = true;
        if (this.screenSizeLessThan) {
          this.paymentButtonData = this.buttonData.filter(button => button.label === 'Choose Payment' || button.label === 'Recalculate')
        } else {
          this.paymentButtonData = this.buttonData.filter(button => button.label !== 'Choose Payment')
        }
      }
    }

    // Handle changes to input properties here
    if (this.screenSizeLessThan) {
      this.paymentButtonData = this.buttonData.filter(button => button.label === 'Choose Payment' || button.label === 'Recalculate')
    } else {
      this.paymentButtonData = this.buttonData.filter(button => button.label !== 'Choose Payment')
    }

    /**
     * BELOW CODE TO BE HANDLED IN SPECIFIC COMPONENT AND NOT IN COMMON COMPONENT. 
     * PLEASE MAINTAIN FLAGS IN RESPECTIVE COMPONENTS FOR KYC AND RECALCULATION.
     * BELOW CODE IS BREAKING IN COMMON RENEWAL SUMMARY PAGE.
     */
    /* if (changes['isKYCDoneSuccessfully']) {
      const newValue = changes['isKYCDoneSuccessfully'].currentValue;
      if (newValue) {
        this.paymentButtonData.forEach(data => {
          data.hide = false;
        })

        if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG == true || this.channelData.BFLAG == "true")) {
          this.paymentButtonData = this.paymentButtonData.filter(data => (data.label != 'Pay Later' && data.label != 'Pay Now'))
          this.buttonData = this.buttonData.filter(data => (data.label != 'Pay Later' && data.label != 'Pay Now'))
        }
      }
    } */
  }


  constructPremiumData() {
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails && this.quoteDetails.Data) {
      this.updateSavedQuotesPremiumData();
    }
    this.premiumDetailsData = this.healthDataStorageService.initializePremiumData();
    this.premiumDetailsData.basicPremium = this.isOrionJourney == 'true' ? this.healthFormData.premiumFormData.basicPremium : Math.floor(parseFloat(this.healthFormData.premiumFormData.basicPremium)).toString();
    this.premiumDetailsData.totalTax = this.isOrionJourney == 'true' ? this.healthFormData.premiumFormData.totalTax : Math.floor(parseFloat(this.healthFormData.premiumFormData.totalTax)).toString();
    this.premiumDetailsData.totalPremium = this.healthFormData.premiumFormData.totalPremium;
    this.premiumDetailsData.adult = this.healthFormData.quoteFormData.adultDetails.length;
    this.premiumDetailsData.child = this.healthFormData.quoteFormData.childDetails.length;
    this.premiumDetailsData.tenure = this.healthFormData.premiumFormData.tenure;
    this.premiumDetailsData.productName = this.healthFormData.quoteFormData.productName;
        this.premiumDetailsData.coverRange = this.healthFormData.quoteFormData.applicantAnnualSum;
    this.premiumDetailsData.zone = this.healthFormData.premiumFormData.zone;
    this.premiumDetailsData.installmentApplicable = this.healthFormData.quoteFormData.InstallmentApplicable == 'Yes';
    this.premiumDetailsData.installmentFrequency = this.healthFormData.quoteFormData.InstallmentFrequency;
    this.premiumDetailsData.installmentTotalPremium = this.healthFormData.premiumFormData.InstallmentTotalPremium;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    if(this.healthFormData.quoteFormData.subProductType === ProductCode.Combo) {
      this.convertSIValue();
    }
      }

  convertSIValue() {
    const originalSIRange = this.healthFormData.quoteFormData.applicantAnnualSum;
    if (!this.utilityService.isEmptyOrNull(originalSIRange)) {
      const splitValues = originalSIRange.split(':');
      this.comboSIValue = `${this.utilityService.easyAmountReader(splitValues[0])} : ${this.utilityService.easyAmountReader(splitValues[1])}`;
    }
  }

  constructTravelPremiumData() {
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    this.travelPremiumDetailsData = this.travelDataStorageService.initializePremiumData();
    this.travelPremiumDetailsData.basicPremium = Math.floor(parseFloat(this.travelFormData.premiumFormData.basicPremium)).toString();
    this.travelPremiumDetailsData.totalTax = Math.floor(parseFloat(this.travelFormData.premiumFormData.totalTax)).toString();
    this.travelPremiumDetailsData.totalPremium = this.travelFormData.premiumFormData.totalPremium;
    this.travelPremiumDetailsData.numberOfTraveller = 1;
    this.travelPremiumDetailsData.totalDays = this.travelFormData.premiumFormData.totalDays;
    this.travelPremiumDetailsData.productName = this.travelFormData.quoteFormData.productName;
    this.travelPremiumDetailsData.country = this.travelFormData.premiumFormData.country;
      }

  private checkScreenWidth() {
    this.subscription.push(this.windowResizeService.getResizeObservable().subscribe(data => {
      this.windowResized = true;
      this.setScreenSizeFlag(data.width);
      this.showPaymentButtons = false;
      this.checkPaymentButtonLabel();
    })
    )
  }

  updateSavedQuotesPremiumData() {
    this.healthFormData.premiumDataSet = true;
    this.healthFormData.premiumFormData.basicPremium = this.quoteDetails.Data.BasicPremium;
    this.healthFormData.premiumFormData.totalTax = this.quoteDetails.Data.TotalTax;
    this.healthFormData.quoteFormData.adultDetails = this.quoteDetails.Data.AdultDetails;
    this.healthFormData.quoteFormData.childDetails = this.quoteDetails.Data.ChildDetails;
    this.healthFormData.premiumFormData.tenure = this.quoteDetails.Data.Tenure;
    this.healthFormData.quoteFormData.productName = this.quoteDetails.Data.ProductName;
    this.healthFormData.quoteFormData.applicantAnnualSum = this.quoteDetails.Data.Members[0].SumInsured ? this.quoteDetails.Data.Members[0].SumInsured.toString() : '';
    if (this.quoteDetails.Data.SubProductType == 34) {
      this.healthFormData.premiumFormData.zone = this.quoteDetails.Data.Zone == 'Zone A' ? 'A' : this.quoteDetails.Data.Zone == 'Zone B' ? 'B' : this.quoteDetails.Data.Zone == 'Zone C' ? 'C' : 'D';
    } else if (this.quoteDetails.Data.SubProductType == 42 || this.quoteDetails.Data.SubProductType == 33 || this.quoteDetails.Data.SubProductType == 40) {
      this.healthFormData.premiumFormData.zone = this.quoteDetails.Data.Zone == 'Zone_I' ? 'A' : this.quoteDetails.Data.Zone == 'Zone_II' ? 'B' : this.quoteDetails.Data.Zone == 'Zone_III' ? 'C' : '';
    }
    this.healthFormData.quoteFormData.InstallmentApplicable = this.quoteDetails.Data.SubProductType == 34 ? 'No' : this.quoteDetails.Data.InstallmentApplicable;
    this.healthFormData.quoteFormData.InstallmentFrequency = this.quoteDetails.Data.InstallmentFrequency;
    this.healthFormData.premiumFormData.InstallmentTotalPremium = this.quoteDetails.Data.InstallmentTotalPremium ? parseInt(this.quoteDetails.Data.InstallmentTotalPremium) : 0;
    this.healthFormData.premiumFormData.totalPremium = this.quoteDetails.Data.TotalPremium;
    this.healthFormData.quoteFormData.quoteSummary = this.quoteDetails.Data.Quotesummary;
    this.healthFormData.premiumFormData.SubProductCode = this.quoteDetails.Data.SubProductCode;
    this.healthFormData.policyDetails.DealId = this.quoteDetails.Data.GenericData.dealID;
    this.healthFormData.quoteFormData.cityId = this.quoteDetails.Data.GenericData.cityId;
    this.healthFormData.quoteFormData.stateId = this.quoteDetails.Data.GenericData.stateId;
    this.healthFormData.kycFormData.KYCStateId = this.quoteDetails.Data.GenericData.kycStateId;
  }

  setScreenSizeFlag(width: number) {
    this.screenSizeLessThan = width <= 990;
  }

  checkPaymentButtonLabel() {
        if (!this.normalButton) {
      this.showPaymentButtons = true;
      if (this.screenSizeLessThan) {
        this.paymentButtonData = this.buttonData.filter(button => button.label === 'Choose Payment' || button.label === 'Recalculate')
      } else {
        this.paymentButtonData = this.buttonData.filter(button => button.label !== 'Choose Payment')
      }
    }
  }

  // Use this method to emit button click data
  emitButtonData(buttonData: object) {

    if (buttonData['label'] == 'Choose Payment') {
      let popupData: PopupModal = {
        popupType: popupType.payment,
        imgName: '',
        header: '',
        description: '',
        buttonLabel: ''
      }
      this.popupService.openPaymentPopup(popupData);
    }
    else {
      this.buttonClicked.emit({ status: true, id: buttonData['id'], label: buttonData['label'] })
    }
  }

  openNav(label?) {
    if (this.productType?.toUpperCase() == 'TRAVEL') {
      var popupData: PopupModal = {
        popupType: popupType.travelPremiumSummary,
        imgName: '',
        header: '',
        description: '',
        buttonLabel: ''
      }
      this.popupService.openPremiumSummaryPopup(popupData);
    } else {
      if (label == 'renewal') {
        var popupData: PopupModal = {
          popupType: popupType.renewalSummary,
          imgName: '',
          header: '',
          description: '',
          buttonLabel: ''
        }
      } else if (this.healthFormData.quoteFormData.subProductType === 23 || this.healthFormData.quoteFormData.subProductType === 38) {
        var popupData: PopupModal = {
          popupType: popupType.zeroTatpremiumSummary,
          imgName: '',
          header: '',
          description: '',
          buttonLabel: ''
        }
      }
      else if ([ProductCode.HAP, ProductCode.Elevate, ProductCode.AB, ProductCode.Combo].includes(this.healthFormData.quoteFormData.subProductType) || this.isOrionJourney == 'true') {
        var popupData: PopupModal = {
          popupType: popupType.hapRefillingSummary,
          imgName: '',
          header: '',
          description: '',
          buttonLabel: ''
        }
      }
      else {
        var popupData: PopupModal = {
          popupType: popupType.premiumSummary,
          imgName: '',
          header: '',
          description: '',
          buttonLabel: ''
        }
      }
      this.popupService.openPremiumSummaryPopup(popupData);
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }
}
