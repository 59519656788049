$primary_font: 'Roboto', sans-serif;

header {
  background-image: linear-gradient(to bottom, #f58220, #b02a30);

  h1 {
    margin: 0 !important;
    display: inline-block;

    a {
      width: 174px;
      height: 32px;
      padding: 0;
      margin: 10px 0;
      display: inline-block;
      background: url(../../../assets/images/logo.png) no-repeat center center/contain;
      text-indent: -9999px;
      vertical-align: top;
    }
  }

  .mdc-form-field>label {
    margin-bottom: 0px !important;
    color: #ffffff;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    background-color: #ffffff !important;
  }
}

.dash_humberger {
  display: flex;
  align-items: center;
  position: relative;

  .humberspan {
    margin-right: 20px;
    cursor: pointer;
  }
}

@media(min-width:990px) {
  header {
    h1 {
      a {
        width: 146px;
      }
    }
  }

  .dash_humberger {
    display: flex;
    align-items: center;
    position: relative;

    .humberger {
      position: absolute;
      left: -32px;
      margin-left: 33px;
    }
  }
}

.fixed-top {
  z-index: 1000 !important;
}

.profile-menu {
  position: relative;
  border: none !important;
  top: 13px;
  right: 0;
  left: -10px;

  .profile-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px; // Adjust as needed
    color: #333; // Adjust as needed
    padding: 0;
    position: relative;
    outline: none;
  }

  ul {
    &.options {
      position: absolute;
      top: calc(100% + -17px);
      right: -17px;
      background-color: #fff; // Adjust as needed
      border: 1px solid #ccc; // Adjust as needed
      padding: 0px;
      list-style: none;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Adjust as needed
      z-index: 1;
      min-width: 212px;

      li {
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: #F8F6E4;
        }

        table {
          width: 100%;

          tr {
            td {
              text-align: left;
              font: 400 13px $primary_font;
              letter-spacing: 0px;
              color: #282828;

              &:first-child {
                width: 30px;
              }

              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

      &.open {
        display: block !important;

      }
    }

    &::before {
      content: '';
      position: absolute;
      top: -13%;
      right: 14%;
      transform: translateX(50%);
      border-width: 9px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
  }



}

.header-home {
  left: -90px;
}

.home-button {
  vertical-align: text-top;
}
