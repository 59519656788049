<ng-container *ngIf="data.length > 0">
    <ng-container *ngFor="let row of data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)">
        <mat-card class="cards">
            <ng-container [ngTemplateOutlet]="cards" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
            <ng-container [ngTemplateOutlet]="buttons" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
        </mat-card>
    </ng-container>
</ng-container>

<footer *ngIf="data.length > 0" class="col-9 tablePag">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"></mat-paginator>
</footer>

<div *ngIf="data.length == 0">
    <mat-card>
        <div class="NoRecordFoundData">
            <!-- <img src=" ../../../../../../../assets/images/BBR/NoRecordFound.svg" alt="No Record Found"
                class="noRecordImg mb-3"> -->
            <h3 class="text-center mb-2 paraMessage"> No data to show</h3>
            <h4 class="text-center mb-0 messagePara"> Try adjusting your search or filter to find your report</h4>
        </div>
    </mat-card>
</div>