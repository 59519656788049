import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { GetRenewalCountRequestPayload, GetRenewalCountResponsePayload } from 'src/app/shared/interface/agent';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { AgentService } from 'src/app/shared/proxy-services/agent.service';
import { LoaderService } from '../loader/loader.service';
import { SideNavService } from 'src/app/shared/services/sidenav.service';
import { LocalStorageService, SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { SidebarMenuItem } from 'src/app/shared/interface/sidebar';
import { DatePipe } from '@angular/common';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { environment } from 'src/environments/environment';
import { PopupModal } from '../popups/popup.modal';
import { popupDescriptionMsg, popupHeader, popupType } from 'src/app/shared/enums/popupEnums';
import { PopupService } from 'src/app/shared/services/popup.service';
import { RenewalService } from 'src/app/shared/proxy-services/renewal-api.service';
import { HealthDataStorage } from 'src/app/modules/health/health.modal';
import { RenewalDataStorageService } from 'src/app/shared/components/sidemenu/renewals/renewal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [DatePipe]
})
export class SidebarComponent implements OnInit {
  opened = true;
  source: string = '';
  loadContent: boolean = false;
  userName: string = '';
  renewalValue: number = 0;
  paymentPending: number = 0;
  currentlyOpenPanelIndex: number = -1;
  currentDate: string = '';
  // Below logic needs to be made more optimized when renewal count will be in pitcure
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  curdate = (this.monthNames[new Date().getMonth()]).toString() + ' ' + new Date().getFullYear().toString();
  dattee: string = this.curdate;
  private subscription: Subscription[] = [];
  sideMenuItems: SidebarMenuItem[] = [];
  agentDetails: AgentMasterStructure;
  isDeveloperDashboard = false;
  healthFormData: HealthDataStorage;

  constructor(private router: Router,
    private agentService: AgentService,
    private sideNavService: SideNavService,
    private storageService: StorageService,
    private datePipe: DatePipe,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private healthDataStorageService: HealthDataStorageService,
    private spinner: LoaderService,
    private popupService: PopupService,
    private renewalService: RenewalService,
    private renewal_service_storage: RenewalDataStorageService) {
  }

  ngOnInit() {

    this.renewalValue = this.storageService.getAESDecryptedData(userEnums.RenewalValue);
    this.paymentPending = this.storageService.getAESDecryptedData(userEnums.PendingPaymentValue);
    // } else {
    //   this.renewalValue = 0;
    //   this.paymentPending = 0;
    // }
    // this.fetchDataAsPerDate();

    this.renewalValue = this.storageService.getAESDecryptedData(userEnums.RenewalValue);
    this.paymentPending = this.storageService.getAESDecryptedData(userEnums.PendingPaymentValue);
    // } else {
    //   this.renewalValue = 0;
    //   this.paymentPending = 0;
    // }
    // this.fetchDataAsPerDate();
    this.constructSidemenuItems();
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.userName = this.storageService.getAESDecryptedData(userEnums.UopqksmlN);
    //  So on toggle when header instructs the side bar to close we are destroying the sidenav
    this.sideNavService.hideSideNav$.subscribe(data => {
      if (data) {
        this.source = 'closePanel';
        this.closeSidebar();
      }
    })
  }

  constructSidemenuItems() {
    this.sideMenuItems = [];
    let tempSidemenuItems: SidebarMenuItem[];
    // You can hide or disabled any menu items by using below array
    // Routing also need to be provided by assigning route path to menu items
    // Menu classes also needs to be provided by below array
    // Even in future how to showcase the menu items in any format use below structure
    if (this.router.url.includes(`${routeEnums.DEVELOPER}`)) {
      this.isDeveloperDashboard = true;
      let userType = this.storageService.getAESDecryptedData(userEnums.UserType);
      tempSidemenuItems = [
        { 'label': 'Authorization', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.AUTHORIZATION}`, 'type': 'list', 'subMenuItems': [], 'hide': userType === 'FE_DEVELOPER' || userType === 'TESTER' || userType == 'SUPPORT' ? true : false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'Logs testing cache:Take 2', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.LOGS}`, 'type': 'list', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'Execute Queries', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.EXECUTE_QUERIES}`, 'type': 'list', 'subMenuItems': [], 'hide': userType === 'FE_DEVELOPER' || userType === 'TESTER' || userType == 'SUPPORT' ? true : false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'Encrypt/Decrypt', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.ENCRYPT_DECRYPT}`, 'type': 'list', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'Read Database', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.READ_DATABASE}`, 'type': 'list', 'subMenuItems': [], 'hide': userType === 'FE_DEVELOPER' || userType === 'TESTER' ? true : false, 'disabled': false, 'active': true, 'showDivider': false },        
        { 'label': 'Clear Static Constants/Cache', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.CLEAR_CACHE_STATIC_VALUES}`, 'type': 'list', 'subMenuItems': [], 'hide': userType === 'FE_DEVELOPER' || userType === 'TESTER' || userType == 'SUPPORT' ? true : false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'Nysa Redirection URL', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.NYSA_REDIRECTION}`, 'type': 'list', 'subMenuItems': [], 'hide': userType === 'FE_DEVELOPER' || userType === 'TESTER' || userType == 'SUPPORT' ? true : false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'URL Shortener', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.URL_SHORT}`, 'type': 'list', 'subMenuItems': [], 'hide': userType === 'FE_DEVELOPER' || userType === 'TESTER' || userType == 'SUPPORT' ? true : false, 'disabled': false, 'active': true, 'showDivider': false },
        { 'label': 'Change Password', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.DEVELOPER}/${routeEnums.CHNGPASS}`, 'type': 'list', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': true, 'showDivider': false },

        // We need logout in between dividers that's why we are passing dividers
        { 'label': '', 'class': '', 'route': '', 'type': 'divider', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': true },
        { 'label': 'Logout', 'class': 'nav-item icon-logout', 'route': `/${routeEnums.DevLogout}`, 'type': 'list', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': true },
        { 'label': '', 'class': '', 'route': '', 'type': 'divider', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': true },
      ];
    } else {
      this.isDeveloperDashboard = false;
      tempSidemenuItems = [
        { 'label': 'Dashboard', 'class': 'nav-item icon-dashboard', 'route': `/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`, 'type': 'list', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': false },
        { 'label': 'Quote', 'class': 'nav-item icon-quote', 'route': `/${routeEnums.HEALTH}/${routeEnums.HAP}`, 'type': 'list', 'subMenuItems': [], 'hide': true, 'disabled': false, 'active': false, 'showDivider': false },
        { 'label': 'Lead Generate', 'class': 'nav-item icon-leadG', 'route': '/lead', 'type': 'list', 'subMenuItems': [], 'hide': true, 'disabled': false, 'active': false, 'showDivider': false },
        {
          'label': 'My Transaction', 'class': 'icon-mytransaction', 'route': `/${routeEnums.Transaction}`, 'type': 'expansion', 'subMenuItems': [
            { 'type': 'list', 'label': 'Saved Quotes', 'route': `/${routeEnums.SavedQuotes}`, hide: false, disabled: false },
            { 'type': 'list', 'label': 'Advanced PID', 'route': `/${routeEnums.PID}`, hide: false, disabled: false },
            { 'type': 'list', 'label': 'My Policies', 'route': `/${routeEnums.MyPolicies}`, hide: false, disabled: false },
            { 'type': 'list', 'label': 'Pay In Slip', 'route': `/${routeEnums.PAY_IN_SLIP}`, hide: false, disabled: false },
            { 'type': 'list', 'label': 'Pending Payment', 'route': `/${routeEnums.PendingPayment}`, hide: false, disabled: false, },
          ],
          'hide': false, 'disabled': false, 'active': false, 'showDivider': false
        },
        {
          'label': 'My Services', 'class': 'icon-myservices', 'route': '/myServices', 'type': 'expansion', 'subMenuItems': [
            { 'type': 'list', 'label': 'Create Customer', 'route': '/create-customer', hide: false, disabled: false },
            { 'type': 'list', 'label': 'Instant Renewal', 'route': '/instant-renewal', hide: false, disabled: false },
            { 'type': 'list', 'label': 'Nil Endorsement', 'route': '/nilEndorsement', hide: true, disabled: false },
            { 'type': 'list', 'label': 'Endorsed Policies', 'route': '/endorsedPolicies', hide: true, disabled: false },
            { 'type': 'list', 'label': 'Brochure', 'route': `/${routeEnums.Brochure}`, hide: true, disabled: false },
            { 'type': 'list', 'label': 'Claims', 'route': '/claims', hide: true, disabled: false },
            { 'type': 'list', 'label': 'Booked Business Report', 'route': `/${routeEnums.BookedBusinessReport}`, hide: false, disabled: false },
            { 'type': 'list', 'label': 'Health Claim', 'route': `/${routeEnums.HealthClaim}`, hide: false, disabled: false },
          ],
          'hide': false, 'disabled': false, 'active': false, 'showDivider': false
        },
        { 'label': 'Sales Genie - CAS', 'class': 'nav-item icon-sales-genie', 'route': `/${routeEnums.SALES_GENIE}`, 'type': 'list', 'subMenuItems': [], 'hide': true, 'disabled': false, 'active': false, 'showDivider': false },
        // We need logout in between dividers that's why we are passing dividers 
        { 'label': '', 'class': '', 'route': '', 'type': 'divider', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': true },
        { 'label': 'Logout', 'class': 'nav-item icon-logout', 'route': `/${routeEnums.Logout}`, 'type': 'list', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': true },
        { 'label': '', 'class': '', 'route': '', 'type': 'divider', 'subMenuItems': [], 'hide': false, 'disabled': false, 'active': false, 'showDivider': true },
      ];
    }
    // patching current date
    let tempDate: Date = new Date();
    this.currentDate = this.datePipe.transform(tempDate, 'MMMM yyyy');
    this.sideMenuItems = tempSidemenuItems;
    this.showSideBarContent();
    // this.loadContent = true;
  }

  // Whenever the component is loaded that means it needs the sidenav to be opened
  showSideBarContent() {
    const sidebar = document.getElementById('sidebar');
    const overlay = document.getElementById('overlay');
    const hamburger = document.getElementById('hamburger');
    if (sidebar && overlay && hamburger) {
      // Open the sidebar
      sidebar.style.width = '270px';
      overlay.style.display = 'block';
      hamburger.classList.add('opened');
    }
    else {
      sidebar.style.width = '0';
      overlay.style.display = 'none';
      hamburger.classList.remove('opened');
    }
  }

  // This api needs to be used later to showcase the fetch renewal and pending count
  // Even if api fails or in any case the renewal count and pending payement is 0
  // fetchDataAsPerDate() {
  //   let tempDate = this.dattee;
  //   let tempArray = tempDate.split(' ');
  //   let monthNumber = this.monthNames.findIndex((month) => {
  //     return month === tempArray[0];
  //   })
  //   let obj: GetRenewalCountRequestPayload = {
  //     Month: monthNumber + 1,
  //     Year: parseInt(tempDate.split(' ')[1]),
  //     QuoteTodayFlag: true
  //   }
  //   this.spinner.show();
  //   this.agentService.renewalCountData(obj).subscribe({
  //     next: (response: GetRenewalCountResponsePayload) => {
  //       if (response.StatusCode == Constants.statusCode_success) {
  //         this.renewalValue = response.Data.RenewalTotalCount;
  //         this.paymentPending = response.Data.PendingPaymentCount;
  //         this.spinner.hide();
  //         this.loadContent = true;
  //         console.log("Load content",this.loadContent,this.renewalValue,this.paymentPending);
  //       }
  //       else if (response.StatusCode != Constants.statusCode_success) {
  //         this.renewalValue = 0;
  //         this.paymentPending = 0;
  //         this.spinner.hide();
  //         this.loadContent = true;
  //       }
  //     },
  //     error: error => {
  //       this.renewalValue = 0;
  //       this.paymentPending = 0;
  //       this.spinner.hide();
  //       this.loadContent = true;
  //     }
  //   })
  // }

  // Below block ensures if one panel is opened then other should be closed
  onPanelOpened(panelIndex) {
    this.source = 'expansionPanel';
    this.currentlyOpenPanelIndex = panelIndex;
  }

  overlayItems(event: Event) {
    event.stopPropagation();
    // Check if the target element is the overlay, and then close the sidebar
    if (event.target === event.currentTarget) {
      this.source = 'overlay'
      this.closeSidebar()
    }
  }

  // To close the side bar use below method
  closeSidebar() {
    // Below overlay is written because if there is expansion panel already clicked previously
    // And now user clicks on overlay
    //  We need to make source empty
    // Did these because expansion panel clicks is closing the sidebar
    if (this.source != 'expansionPanel') {
      const sidebar = document.getElementById('sidebar');
      const overlay = document.getElementById('overlay');
      const hamburger = document.getElementById('hamburger');

      sidebar.style.width = '0';
      overlay.style.display = 'none';
      hamburger.classList.remove('opened');
      // Explicitly destroying to remove the instance
      this.ngOnDestroy();
      if (this.source != 'closePanel') {
        this.toggleHeaderFlag();
      }
    }
  }

  navigate(route: string) {
    this.source = 'navigation';
    // if(route == '/lead'){
    //   const currentUrl = window.location.href;

    //   // Append a string to the URL
    //   const updatedUrl = currentUrl + '/redirect.html';

    //   // Redirect to the updated URL
    //   window.location.href = updatedUrl;
    // }
    if (route == `/${routeEnums.Logout}` || route == `/${routeEnums.DevLogout}`) {
      let popupData: PopupModal = {
        popupType: popupType.confirmation,
        imgName: '',
        header: popupHeader.confirmlogout,
        description: popupDescriptionMsg.backButtonLogoutDescription,
        buttonLabel: '',
        buttonAction: ''
      }
      this.popupService.openGenericPopup(popupData);
      this.popupService.logout$.subscribe(status => {
        if (status) {
          this.healthDataStorageService.resetHealthDataStorage();
          this.healthDataStorageService
          this.localStorageService.clearLocal();
          this.sessionStorageService.clear();
          if (route == `/${routeEnums.DevLogout}`) {
            this.router.navigate(['/', routeEnums.DEVELOPER]);
          } else {
            this.router.navigate(['/', routeEnums.LOGIN]);
          }
        }
      });
      // Below step is done because data is getting cached . So reset form data and clear local.
      // window.location.href = environment.logoutRedirectionUrl;
      // Logout fucntionlaity for Sanity and Prod
      // let redirectionUrl = environment.production ? `${environment.existingUrl}\WebPages\Login.aspx`: `${environment.existingUrl}/home`;
      // window.location.href = redirectionUrl;
    } else if (route == `/${routeEnums.SALES_GENIE}`) {
      const UserID: string = String(this.storageService.getAESDecryptedData(userEnums.AgentDetails).AgentID);
      this.renewalService.salesGenieComponent({ UserID: UserID, PolicyID: '' }).subscribe(res => {
        if (res.StatusCode === Constants.statusCode_success) {
          let popupData: PopupModal = {
            popupType: popupType.salesGenie,
            imgName: '',
            header: 'Sales Genie - CAS',
            description: 'Sales Genie - CAS',
            data: res.Data,
            buttonLabel: '',
            buttonAction: ''
          }
          this.popupService.openSalesGeniePopup(popupData);
        }
      })

    } else {
      this.healthDataStorageService.resetHealthDataStorage();
      this.renewal_service_storage.resetRenewalDataStorage();
      this.router.navigate([route]);
    }
    // As we are navigating to other menu items we need to instruct the sidenav to be destroyed
    // And also header component needs to be informed
    this.closeSidebar();
    this.toggleHeaderFlag();
  }

  toggleHeaderFlag() {
    this.sideNavService.hideSidenav(true);
  }

  ngOnDestroy() {
    this.subscription.forEach(subscriptions => {
      subscriptions.unsubscribe();
    })
  }
}
