import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  HostListener,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { Subscription } from 'rxjs';
import { SideNavService } from 'src/app/shared/services/sidenav.service';
import { LocalStorageService, SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { LoaderService } from '../loader/loader.service';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { DeveloperDashboardService } from 'src/app/developer-dashboard/services/developer-dashboard.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { GetRenewalCountRequestPayload, GetRenewalCountResponsePayload } from 'src/app/shared/interface/agent';
import { AgentService } from 'src/app/shared/proxy-services/agent.service';
import { Constants } from 'src/app/shared/constants/constants';
import { ChannelData, SwapParameters } from 'src/app/shared/interface/swapPrameters';
import { LibSessionStorageService, NotificationUiComponent } from 'projects/i-pushnotification/src/public-api';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import { popupType, popupHeader, popupDescriptionMsg } from 'src/app/shared/enums/popupEnums';
import { PopupModal } from '../popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { AgentService_A } from 'src/app/shared/proxy-services/AuthService/agent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() dashBoardStatus = false;
  showHamburger = false;
  openSidenav: boolean = false;
  isLoading: boolean = true;
  renewalValue: number = 0;
  paymentPending: number = 0;
  currentlyOpenPanelIndex: number = -1;
  currentDate: string = '';
  showMenu = false;
  credential: { Uowkjsdjssrme: string, Powkjsdjssrd: string } = { Uowkjsdjssrme: '', Powkjsdjssrd: '' };
  // Below logic needs to be made more optimized when renewal count will be in pitcure
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  curdate = (this.monthNames[new Date().getMonth()]).toString() + ' ' + new Date().getFullYear().toString();
  dattee: string = this.curdate;
  loadContent: boolean = false;
  channelData: ChannelData;
  notification: NotificationUiComponent;
  updatedNotificationData = [];
  agentDetails;
  isDeveloper = false;
  showHomeIcon = true;
  agentInfo: AgentMasterStructure;
  private isHostListenerActive: boolean = false;
  hideDashboard: boolean = this.dashBoardStatus;
  loginForm: any;
  private subscription: Subscription[] = [];
  currentURL: string;
  showHeader: boolean = true;
  imid: any;
  isMFA: boolean = false;

  constructor(private sessionStorageService: SessionStorageService,
    private sideNavService: SideNavService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderservice: LoaderService,
    private healthDataStorageService: HealthDataStorageService,
    private developerDashboardService: DeveloperDashboardService,
    private storageService: StorageService,
    private agentService: AgentService,
    private encryptDecryptService: EncryptDecryptService,
    private spinner: LoaderService,
    private localStorageService: LocalStorageService,
    private popupService: PopupService,
    private libSession: LibSessionStorageService,
    private mfaservice: MFAService,
    private agentservice: AgentService_A,
  ) {

    // this.currentURL = (pLocation as any).location.href;

    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.currentURL = this.route.snapshot['_routerState'].url;
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.loginForm = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
    let uid =  'Uowkjsdjssrme';
    let pid = 'Powkjsdjssrd';
    this.imid = this.storageService.getAESDecryptedData(userEnums.UopqksmlN);
    this.channelData = {
      "FT": this.channelData ? this.channelData.FT : "NEW",
      "IMID": this.imid,
      "PSWD": this.loginForm[uid],
      "PRDT": this.channelData ? this.channelData.PRDT : "Health",
      "SPRDT": this.channelData ? this.channelData.SPRDT : "",
      "CN": this.channelData ? this.channelData.CN : "NYSAHEALTH",
      "VT": this.channelData ? this.channelData.VT : "Portal",
      "UT": this.channelData ? this.channelData.UT : "Agent",
      "CIP": this.channelData ? this.channelData.CIP : "NYSAHEALTH",
      "UN": this.loginForm[pid] || this.imid,
      "ST": this.channelData ? this.channelData.ST : "login",
      "BT": this.channelData ? this.channelData.BT : "",
      "BFLAG": this.channelData ? this.channelData.BFLAG : "",
      "TOKEN":this.channelData ? this.channelData.TOKEN : ""
    }
    this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, this.channelData);
  }

  ngOnInit() {
    if (this.storageService.getAESDecryptedData(userEnums.IsDeveloper)) {
      this.isDeveloper = true;
    }
    else {
      this.isDeveloper = false;
    }
    if (this.channelData != null) {
      this.showHeader = !(this.channelData.CN.includes('BANCA') || this.channelData.CN.includes('PRO') || this.channelData.CN.includes('NYSAMOTORRIO'));
      this.showHomeIcon = !(this.channelData.CN.includes('BANCA') || this.channelData.CN.includes('PRO') || this.channelData.CN.includes('NYSAMOTORRIO'));
      this.showHamburger = !(this.channelData.CN.includes('BANCA') || this.channelData.CN.includes('PRO') || this.channelData.CN.includes('NYSAMOTORRIO')|| (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG == "true")));
    }
    this.showHamburger = Boolean(this.sideNavService.getUserAuthenticatedStatus());
    // Pass Rsa enc uname and pwd in notification
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
    this.agentDetails = agentDetails;
    this.agentInfo = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.credential.Uowkjsdjssrme = this.encryptDecryptService.finalRsaEncryption(agentDetails.Uowkjsdjssrme);
    this.credential.Powkjsdjssrd = this.encryptDecryptService.finalRsaEncryption(agentDetails.Powkjsdjssrd);
    this.isMFA = this.channelData.CN.includes('MFA');
    // Below block is written because whenever user clicks on overlay or navigate to some link
    // Side nav gets detroyed but header is not aware whether it needs to close sidenav or not
    this.sideNavService.hideSideNav$.subscribe(data => {
      if (data) {
        this.openSidenav = false;
      }
    })

    this.libSession.notificationData$.subscribe(notifyData => {

      if (notifyData['status']) {
        this.updatedNotificationData = notifyData['notificationData'];
      }
    })
    // this.listen();

    //Below block is added for to disable hamburger when loader is active
    this.loaderservice.loaderShow.subscribe(
      () => {
        this.isLoading = false;
      }
    );
    //Below block is added for to enable hamburger when loader is active
    this.loaderservice.loaderHide.subscribe(
      () => {
        this.isLoading = true;
      }
    );
    if (this.channelData != null) {
      if ((this.channelData.CIP != "MFANYSACYBER" && this.channelData.CIP != 'NYSAHEALTH' && this.channelData.CIP != 'MFANYSAHEALTH' && this.channelData.CIP != 'MFANYSATRAVEL' && this.channelData.CIP != 'MFANYSAHOME' && this.channelData.CIP != 'NYSAMOTOR') || (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG || this.channelData.BFLAG == "true"))) {
        this.showHamburger = false;
      } else{
        this.showHamburger = true; 
      }
    }

  }

  ngOnChanges() {
    this.hideDashboard = this.dashBoardStatus;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!(event.target instanceof Element)) {
      return;
    }
    const closestBellBtn = (event.target as Element).closest('.profile-menu');
    if (!closestBellBtn && this.showMenu) {
      this.closeMenu();
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    if (this.showMenu && !this.isHostListenerActive) {
      document.addEventListener('click', this.onClick);
      this.isHostListenerActive = true;
    } else if (!this.showMenu && this.isHostListenerActive) {
      document.removeEventListener('click', this.onClick);
      this.isHostListenerActive = false;
    }
  }

  closeMenu() {
    this.showMenu = false;
    if (this.isHostListenerActive) {
      document.removeEventListener('click', this.onClick);
      this.isHostListenerActive = false;
    }
  }

  notifiDec(event) {
  }

  toggleSidenav() {
    if (this.isDeveloper && this.isLoading) {
      if (this.openSidenav) {
        // Whenever user toggles the sidenav and it was opened we need to close it
        // And communicate  the same to sidenav that it needs to be detroyed
        this.sideNavService.hideSidenav(true);
        this.openSidenav = false;
      }
      else if (!this.openSidenav) {
        this.openSidenav = true;
      }
    }
  }
  navigateToDashboard() {
    // do not remove this 
    // let userdata: SwapParameters = this.storageService.getAESDecryptedData(
    //   userEnums.SwapParametersLogout
    // );
    // if (this.channelData.CN.includes('MFA')) {
    //   let requestpayload = {
    //     userId: userdata.loginDetails.username,
    //     appId: userdata.productType === "Health" ? 2 : userdata.productType === "Travel" ? 3 : userdata.productType === "Home" ? 4 : userdata.productType === "Corporate" ? 5 : 2,
    //   };

    //   this.mfaservice.mfaDashbordRedirect(requestpayload).subscribe((res) => {
    //     if (res.StatusCode == Constants.statusCode_success) {
    //       window.location.href = res.Data.redirectUrl;
    //       return;
    //     }
    //   });
    // } else {
    //   if (this.channelData.CN == 'NYSAHEALTH' && !this.isDeveloper) {
    //     this.router.navigate([routeEnums.HEALTH + '/' + routeEnums.DASHBOARD]);
    //   }
    // }

    if ((this.channelData.CN == "NYSAHOME" || this.channelData.CN == 'NYSAHEALTH' || this.channelData.CN.includes('MOTOR') || this.channelData.CN.includes('MFA')) && !this.channelData.CN.includes('NYSAMOTORRIO') && !this.isDeveloper) {
      this.router.navigate([routeEnums.HEALTH + '/' + routeEnums.DASHBOARD]);
    }
  }

  logout() {
    // Below step is done because data is getting cached . So reset form data and clear local.
    // this.healthDataStorageService.resetHealthDataStorage();
    // this.sessionStorageService.clear();
    // this.router.navigate(['/', routeEnums.LOGIN]);

    let popupData: PopupModal = {
      popupType: popupType.confirmation,
      imgName: '',
      header: popupHeader.confirmlogout,
      description: popupDescriptionMsg.backButtonLogoutDescription,
      buttonLabel: '',
      buttonAction: ''
    }
    this.popupService.openGenericPopup(popupData);
    this.popupService.logout$.subscribe(status => {
      if (status) {
        // if (this.channelData.CN.includes(Constants.channelNameMFA)) {
        //   this.agentservice.mfaLogout(2, this.channelData.UN, this.channelData.PRDT).subscribe((res) => {
        //     if (res.StatusCode == 1) {
        //       this.clearAfterLogout();
        //       console.log("final decrypted output is ", res);
        //       window.location.href = res.Data.redirectUrl;
        //       return;
        //     }
        //   });
        // } else {
          this.clearAfterLogout();
          if (this.channelData.CN.includes('MOTOR') || this.channelData.CN.includes('MFA')) {
            window.location.href = environment.nysaMotorLoginRedirection;
            return;
          } else if (this.channelData.CN.includes('BANCA')) {
            window.location.href = environment.nysaBancaLoginRedirection;
            return;
          }
          // this.healthDataStorageService
          // this.localStorageService.clearLocal();
          // this.sessionStorageService.clear();
          // if (route == `/${routeEnums.DevLogout}`) {
          //   this.router.navigate(['/', routeEnums.DEVELOPER]);
          // } else {
          // this.agentservice.logout();
          this.router.navigate(['/', routeEnums.LOGIN]);
          //}
        // }
      }
    });
  }

  navigateToMFADashboard() {
    if (this.isMFA) {
      const requestPayload = {
        userId: this.channelData.IMID,
      };

      this.subscription.push(this.mfaservice.redirectToMFADashboard(requestPayload).subscribe({
        next: (response) => {
          if (response.StatusCode === Constants.statusCode_success) {
            window.location.href = response.Data;
          } else {
            const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.mfaDashbordRedirectFailure);
            this.popupService.openGenericPopup(errorPopupData);
          }
        },
        error: (error) => {
          const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.mfaDashbordRedirectFailure);
          this.popupService.openGenericPopup(errorPopupData);
        }
      }))
    } else {
      const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.mfaDashbordRedirectFailure);
      this.popupService.openGenericPopup(errorPopupData);
    }
  }

  clearAfterLogout() {
    this.healthDataStorageService.resetHealthDataStorage();
    this.localStorageService.clearLocal();
    this.sessionStorageService.clear();
  }
  ngOnDestroy() {
    this.subscription.forEach(subscriptions => {
      subscriptions.unsubscribe();
    })
  }

}
