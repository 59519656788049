<!-- Premium Summary -->
<div class="footer">
    <div class="container footerContainer">
        <div class="row">
            <!-- Premium Details Structure starts -->

            <div class="text-right widthpremium footerInsured col" *ngIf="showPremiumInfo"
                [ngClass]="{'summaryShowBtn': showPaymentButtons, 'fullsumView': !showPaymentButtons }">
                <ng-container *ngIf="productType?.toUpperCase() == 'HEALTH'">
                    <table class="premiumTable Premiumwebview paymentPreview">
                        <tr>
                            <td width="90">
                                <span class="dimview">Members</span>
                            </td>
                            <td>
                                <span *ngIf="this.healthFormData.quoteFormData.subProductType != 33">{{premiumDetailsData.adult}} Adult, {{premiumDetailsData.child}} Child</span>
                                <span *ngIf="this.healthFormData.quoteFormData.subProductType == 33">{{premiumDetailsData.adult}} Adult</span>
                            </td>
                            <td width="45" *ngIf="premiumDetailsData.coverRange.length>0">
                                <span class="dimview">Cover</span>
                            </td>
                            <td [style.width]="[46, 47].includes(healthFormData.quoteFormData.subProductType) ? '160px' : '90px'" *ngIf="premiumDetailsData.coverRange.length>0">
                                <span>{{premiumDetailsData.coverRange.includes('Unlimited') 
                                    ? 'Unlimited' 
                                    : (this.healthFormData.quoteFormData.subProductType === 46) 
                                      ? '₹ ' + healthFormData.quoteFormData?.policyRange + ' (' + comboSIValue + ')'
                                      : (this.healthFormData.quoteFormData.subProductType === 47) 
                                        ? (utilityService.easyAmountReader(premiumDetailsData.coverRange) + ' SI & ' + utilityService.easyAmountReader(this.healthFormData.quoteFormData?.voluntaryDeductible) + ' VD') 
                                        : utilityService.easyAmountReader(premiumDetailsData.coverRange)}}</span>
                            </td>
                            <td>
                                <span class="dimview">Total premium</span>
                                <span>₹{{ premiumDetailsData?.totalPremium ?
                                    utilityService.convertStringtoCurrency(premiumDetailsData.totalPremium):''}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td *ngIf="!premiumDetailsData.installmentApplicable">
                                <span class="dimview">Base premium</span>
                            </td>
                            <td *ngIf="!premiumDetailsData.installmentApplicable">
                                <span>₹{{premiumDetailsData?.basicPremium ?
                                    utilityService.convertStringtoCurrency(premiumDetailsData?.basicPremium): ''}} +
                                    ₹{{premiumDetailsData?.totalTax ?
                                    utilityService.convertStringtoCurrency(premiumDetailsData?.totalTax):
                                    ''}} (18% GST)</span>
                            </td>
                            <td width="90" *ngIf="premiumDetailsData.installmentApplicable">
                                <span class="dimview">EMI Plan</span>
                            </td>
                            <td *ngIf="premiumDetailsData.installmentApplicable">
                                <span>₹{{utilityService.convertStringtoCurrency(premiumDetailsData?.installmentTotalPremium)+'/'+this.premiumDetailsData.installmentFrequency}}</span>
                            </td>

                            <!-- Other than hap zone -->
                            <td width="45"
                                *ngIf="premiumDetailsData.zone.length>0 && (![42, 45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType))">
                                <span class="dimview ">Zone</span>
                            </td>

                            <!-- Other than hap zone -->
                            <td width="90"
                                *ngIf="premiumDetailsData.zone.length>0 && (![42, 45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType))">
                                <span>Zone - {{premiumDetailsData?.zone}}</span>
                            </td>

                            <!-- Zone block added for Hap Refilling -->
                            <td width="45"
                                *ngIf="this.healthFormData.quoteFormData.zone && ([42, 45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType))">
                                <span class="dimview ">Zone</span>
                            </td>
                            <td width="90"
                                *ngIf="this.healthFormData.quoteFormData.zone && ([42, 45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType))">
                                <span
                                    *ngIf="!this.healthFormData.quoteFormData.reviseZone">{{healthFormData.quoteFormData.zone}}</span>
                                <span
                                    *ngIf="this.healthFormData.quoteFormData.reviseZone">{{healthFormData.quoteFormData.reviseZone}}</span>
                            </td>

                            <!-- Zone block added for Orion HAP -->

                            <ng-container *ngIf="this.healthFormData.quoteFormData.zone && isOrionJourney == 'true'">
                                <td class="table-cell-width-45">
                                    <span class="dimview ">Zone</span>
                                </td>
                                <td class="table-cell-width-90">
                                    <ng-container
                                        *ngIf="this.healthFormData.quoteFormData.reviseZone; then revisedZone  else noRevisedZone"></ng-container>
                                    <ng-template
                                        #revisedZone><span>{{healthFormData.quoteFormData.reviseZone}}</span></ng-template>
                                    <ng-template
                                        #noRevisedZone><span>{{healthFormData.quoteFormData.zone}}</span></ng-template>
                                </td>
                            </ng-container>


                            <!-- ZOne block added because zone is not present in every componnet. Just to showcase the gap in hb and asp -->
                            
                            <td width="45" style="visibility: hidden;"
                                *ngIf="this.healthFormData.quoteFormData.subProductType == 22 || this.healthFormData.quoteFormData.subProductType == 20">
                                <span class="dimview ">Zone</span>
                            </td>
                            <td width="90" style="visibility: hidden;"
                                *ngIf="this.healthFormData.quoteFormData.subProductType == 22 || this.healthFormData.quoteFormData.subProductType == 20">
                                <span>Zone - {{premiumDetailsData?.zone}}</span>
                            </td>
                            <!-- Zone ends -->
                            <td>
                                <span class="viewsumm" style="cursor: pointer;" (click)="openNav('')">View full
                                    summary</span>
                            </td>
                        </tr>
                    </table>
                </ng-container>
                <ng-container *ngIf="productType?.toUpperCase() == 'TRAVEL'">
                    <table class="premiumTable Premiumwebview paymentPreview">
                        <tr>
                            <td>
                                <span>{{travelPremiumDetailsData.numberOfTraveller}} Traveller</span>
                            </td>
                            <td>
                                <span class="dimview">No of Days</span>
                                <span> {{ travelPremiumDetailsData.totalDays }}</span>
                            </td>
                            <td>
                                <span class="dimview">Total premium</span>
                                <span>₹{{ travelPremiumDetailsData?.totalPremium ?
                                    utilityService.convertStringtoCurrency(travelPremiumDetailsData.totalPremium):''}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ng-container>
                                    <span class="dimview">Base premium</span>
                                    <span>₹{{travelPremiumDetailsData?.basicPremium ?
                                        utilityService.convertStringtoCurrency(travelPremiumDetailsData?.basicPremium):
                                        ''}} +
                                        ₹{{travelPremiumDetailsData?.totalTax ?
                                        utilityService.convertStringtoCurrency(travelPremiumDetailsData?.totalTax):
                                        ''}} (18% GST)</span>
                                </ng-container>
                            </td>
                            <td>
                                <span class="dimview ">Country</span>
                                <span>{{travelPremiumDetailsData?.country}}</span>
                            </td>
                            <td>
                                <span class="viewsumm" style="cursor: pointer;" (click)="openNav()">View full
                                    summary</span>
                            </td>
                        </tr>
                    </table>
                </ng-container>
            </div>
            <!-- Premium Details Structure Ends -->

            <!-- renewal footer start-->
            <!-- [ngClass]="{'fullsumViewRenewal': showRenewalFooter, 'fullsumViewSubmit':!showRenewalFooter}" -->
            <!-- showRenewalFooter {{showRenewalFooter}} -->
            <div class="text-right widthpremium footerInsured fullsumView col"
                *ngIf="showRenewalFooter && !zerotatFooter">
                <table class="premiumTable Premiumwebview paymentPreview">
                    <tr>
                        <td>
                            <span class="dimview">Base Premium</span>
                            <span>₹{{this.footerData?.BasicPremium?utilityService.convertStringtoCurrency(this.footerData?.BasicPremium):0}}</span>
                        </td>
                        <td>
                            <span class="dimview">GST</span>
                            <span>₹{{this.footerData?.TotalTax?utilityService.convertStringtoCurrency(this.footerData?.TotalTax):0}}</span>
                        </td>
                        <td>
                            <span class="dimview">Total Premium</span>
                            <span>₹{{utilityService.convertStringtoCurrency(footerTotalPremium)}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="!this.renewalDataStorage?.IsHaapRefiling && this.footerData?.hideOpCoverShowTenure ? !this.footerData?.hideOpCoverShowTenure : true">
                            <span class="dimview">Optional Covers</span>
                            <span>₹{{this.footerData?.OptionalCovers?utilityService.convertStringtoCurrency(this.footerData?.OptionalCovers):0}}</span>
                        </td>
                        <td *ngIf="this.footerData?.hideOpCoverShowTenure ? this.footerData?.hideOpCoverShowTenure : false">
                            <span class="dimview">Tenure</span>
                            <span>{{this.footerData?.Tenure}}{{this.footerData?.Tenure == '1' ? ' year' : ' years'}}</span>
                        </td>
                        <td *ngIf="this.footerData?.hideSubLimitShowSI ? !this.footerData?.hideSubLimitShowSI : true">
                            <span class="dimview">Sublimit</span>
                            <span>₹0</span>
                        </td>
                        <td *ngIf="this.footerData?.hideSubLimitShowSI ? this.footerData?.hideSubLimitShowSI : false"></td>
                        <!-- <td *ngIf="this.footerData?.hideSubLimitShowSI ? this.footerData?.hideSubLimitShowSI : false">
                            <span class="dimview">Sum Insured</span>
                            <span>₹{{utilityService.convertStringtoCurrency(this.footerData?.SumInsured)}}</span>
                        </td> -->
                        <td>
                            <span class="viewsumm" style="cursor: pointer;" (click)="openNav('renewal')">View full
                                summary</span>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- renewal footer end -->

            <!-- Dynamic Button Layout Starts -->
            <!-- Rendering all buttons except payment buttons -->


            <div class="quotebuttons paymentBtnsGroup paymentPreview textAlignEnd" *ngIf="normalButton && !renewButton"
                [ngClass]="{'quotebuttons': showPremiumInfo || showRenewalFooter, 
                'genQuote': !showPremiumInfo && !showRenewalFooter, 'col-12': !showPremiumInfo }">
                <ng-container *ngFor="let buttonData of buttonData; let i = index;">
                    <ng-container [ngTemplateOutlet]="normalButtonTemplate"
                        [ngTemplateOutletContext]="{ $implicit: { button: buttonData} }">
                    </ng-container>
                </ng-container>
            </div>

            <div class="renewalwithPrebtn renewalWithoutPrebtn" *ngIf="renewButton && !zerotatFooter"
                [ngClass]="{'renewalwithPrebtn': showPremiumInfo || showRenewalFooter, 'renewalWithoutPrebtn': !showPremiumInfo && !showRenewalFooter, 'col text-lg-right': !zerotatFooter }">

                <ng-container *ngFor="let buttonData of buttonData; let i = index;">
                    <ng-container [ngTemplateOutlet]="normalButtonTemplate"
                        [ngTemplateOutletContext]="{ $implicit: { button: buttonData} }">
                    </ng-container>
                </ng-container>
            </div>


            <!-- Payment Buttons -->
            <div class="quotebuttons summaryQuoteBtn paymentBtnsGroup paymentPreview textAlignEnd"
                [ngClass]="{'col-12': zerotatFooter}" *ngIf="showPaymentButtons">
                <button *ngFor="let paymentData of paymentButtonData; let j = index;"
                    [ngClass]="j == 0 ? 'primary-button-new primBtnLink': 'secondary-button payment_overBtn'">
                    <span>
                        <ng-container [ngTemplateOutlet]="normalButtonTemplate"
                            [ngTemplateOutletContext]="{ $implicit: { button: paymentData} }">
                        </ng-container>
                    </span>
                </button>&nbsp;
            </div>

            <!-- To render all buttons-->
            <ng-template #normalButtonTemplate let-context>
                <button mat-raised-button *ngIf="!context.button.hide" [ngClass]="{'primary-button': context.button.type == 'primary', 
               'secondarybtn_2 mr-1' : context.button.type == 'secondary',
               'button_payment' : context.button.type == 'payment' }" type="button"
                    [disabled]="context.button.disabled" (click)="emitButtonData(context.button)">
                    {{ context.button.label }}
                </button>
            </ng-template>
            <!-- Dynamic Button Layout Ends -->

        </div>
    </div>
</div>