<div class="scrollDiv bbrTable">
    <table class="tableWidth bgWhite" *ngIf="data.length > 0">
        <caption class="hidden-caption">Headers and Row</caption>
        <th class="hidden-caption">Headers and Row</th>
        <thead class="thead">
            <tr>
                <ng-container [ngTemplateOutlet]="headers"
                    [ngTemplateOutletContext]="{ $implicit: data }"></ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)" class="trData">
                <ng-container [ngTemplateOutlet]="rows" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
            </tr>
        </tbody>
    </table>
</div>

<footer *ngIf="data.length > 0" class="tablePag">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"></mat-paginator>
</footer>

<div *ngIf="data.length == 0">
    <mat-card>
        <div class="NoRecordFoundData">
            <img src=" ../../../../../../../assets/images/BBR/NoRecordFound.svg" alt="No Record Found"
                class="noRecordImg mb-3">
            <h3 class="text-center mb-2 paraMessage"> No data to show</h3>
            <h4 class="text-center mb-0 messagePara"> Try adjusting your search or filter to find your report</h4>
        </div>
    </mat-card>
</div>