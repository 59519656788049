import { Injectable } from '@angular/core';
import { GenericService } from 'src/app/shared/services/generic.service';
import { environment } from 'src/environments/environment';
import { NysaRedirectionRequestPayload, NysaRedirectionResponsePayload, ShortURLRequestPayload, changePasswordPayload, clearValuesPayload, executeQueryPayload, executeQueryResponseData, getAllTokenPayload, getLogsRequestPayload, getLogsResponseData, getQueryTemplatesResponseData } from '../interfaces/developer-dashboard.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeveloperDashboardService {

  readonly baseUrl = `${environment.baseURL}Developer`;
  readonly WRAPPER_URL = `${environment.baseURL}Wrapper`;
  readonly SHORT_URL = '/CreateShortURL';
  readonly GET_LOGS = '/ReadLog';
  readonly GET_QUERY_TEMPLATES = '/GetQueryTemplate';
  readonly EXECUTE_QUERY = '/excecuteQuery';
  readonly ENCRYPT_DECRYPT = '/DecryptData';
  readonly GET_DATABASE = '/getDatabaseTable';
  readonly CHNGPASS = '/ChangePassword';
  readonly GET_STATIC_CACHE_CONSTANTS = '/GetClearCacheAndStaticValue';
  readonly CLEAR_VALUES = '/ClearCacheAndStaticValue';
  readonly GET_ALL_TOKEN = '/GetAllToken';
  readonly NYSA_REDIRECTION = `${environment.baseURL}Utility/ProductRedirection`;
  readonly NYSA_REDIRECTION_Developer = `${environment.baseURL}Intranet/NysaRedirection`;
  env: string = 'sanity';

  constructor(private generic: GenericService,) { }

  getLogs(data: getLogsRequestPayload): Observable<getLogsResponseData> {
    return this.generic.genericService<getLogsResponseData>(this.baseUrl + this.GET_LOGS, data);
  }

  getAllToken(data: getAllTokenPayload) {
    return this.generic.genericService<any>(this.baseUrl + this.GET_ALL_TOKEN, data);
  }

  getQueryTemplates(): Observable<getQueryTemplatesResponseData> {
    return this.generic.genericService<getQueryTemplatesResponseData>(this.baseUrl + this.GET_QUERY_TEMPLATES);
  }


  executeQuery(data: executeQueryPayload): Observable<executeQueryResponseData> {
    return this.generic.genericService<executeQueryResponseData>(this.baseUrl + this.EXECUTE_QUERY, data);
  }

  encryptDecryptReqRes(type: string, key: string): Observable<any> {
    const data = {
      type: type,
      key: key
    };
    return this.generic.genericService<any>(this.baseUrl + this.ENCRYPT_DECRYPT, data);
  }

  getDatabase() {
    return this.generic.genericService<any>(this.baseUrl + this.GET_DATABASE);
  }

  getNysaRedirectionURL(data: NysaRedirectionRequestPayload) {
    return this.generic.genericService<NysaRedirectionResponsePayload>(this.NYSA_REDIRECTION, data);
  }

  getNysaRedirectionURL_Developer(data: NysaRedirectionRequestPayload) {
    return this.generic.genericService<NysaRedirectionResponsePayload>(this.NYSA_REDIRECTION_Developer, data);
  }

  getShortURL(data: ShortURLRequestPayload) {
    return this.generic.genericService<any>(this.WRAPPER_URL + this.SHORT_URL, data);
  }

  changePassword(data: changePasswordPayload) {
    return this.generic.genericService<any>(this.baseUrl + this.CHNGPASS, data);
  }

  getStaticConstants() {
    return this.generic.genericService<any>(this.baseUrl + this.GET_STATIC_CACHE_CONSTANTS);
  }

  clearValues(data: clearValuesPayload) {
    return this.generic.genericService<any>(this.baseUrl + this.CLEAR_VALUES, data);
  }

  getRedisCache(data) {
    const endpoint = `${environment.baseURL}` + 'RedisCashes/GetPlansFromDeals?DealID=' + data;
    return this.generic.genericGetService<executeQueryResponseData>(endpoint);
  }

}
