import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @ContentChild('headers') headers!: TemplateRef<any>;
  @ContentChild('rows') rows!: TemplateRef<any>;
  @Input() list = [];
  data = this.list;
  pageSizeOptions: number[] = [10, 25, 100];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  length: number = this.data.length;

  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnChanges() {
    this.data = this.list;
    this.length = this.data.length;
    this.pageIndex = 0;
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }
  
}